/* autoprefixer grid: autoplace */

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
	background: #35a29b;
	height: 100%;
	color: #000;
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	font-family: 'Arial', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
  background-size: cover;
  background-image: url("/main_background.webp");
  background-position: center top;
}

#root {
	height: 100%;
}

* {
	-webkit-tap-highlight-color: transparent;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
}

h2 {
    font-size: 60px;
}

h3 {
    font-size: 36px;
}

h4 {
    font-size: 24px;
}
input,
button,
select {
  cursor: pointer;
	font-family: 'Arial', sans-serif;
}
a:focus, button:focus, input:focus, select:focus { outline: thin dotted; }
input:disabled, button:disabled {
  cursor:default;
}
.video-js.vjs-quality-menu {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media screen and (max-width: 600px) {
  body {
    background-size: contain;
    background-image: url(/mob-bg.svg);
    background-position: 0px 0px;
  }
}